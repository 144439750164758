import { useMemo } from "react";

interface ClassListClass {
  class: string;
  active?: boolean;
}

class ClassList extends Set<string> {
  constructor(...classes: (string | ClassListClass)[]) {
    super(
      classes
        .filter((c) => typeof c === "string" || c.active)
        .map((c) => (typeof c === "string" ? c : c.class))
    );
  }

  get classNames(): string {
    return Array.from(this).join(" ");
  }

  toString(): string {
    return this.classNames;
  }
}

export function useClassList(
  ...classes: (string | ClassListClass)[]
): ClassList {
  const classList = useMemo<ClassList>(() => {
    return new ClassList(...classes);
  }, [classes]);

  return classList;
}
